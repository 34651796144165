"use client";

import { FC, MutableRefObject, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MobileZipcode from "./MobileZipcode";
import ZipcodeModal from "./ZipcodeModal";
import { GoogleGeocodeLocation } from "utils/googleMapsUtils";
import useMobileDetect from "hooks/useMobileDetect";

interface LocationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  openRef: MutableRefObject<boolean>;
  zipcode: string;
  setZipcode: (newZipcode: string) => void;
  isLoaded: boolean;
  apiKey: string;
  setLocation: (location: string) => void;
  geocodeLocation: GoogleGeocodeLocation;
  setGeocodeLocation: (location: GoogleGeocodeLocation) => void;
  handleSubmit: (e, modalZipcode?: string) => void;
}

const LocationModal: FC<LocationModalProps> = (props) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const { isMobile, isSmallVertical } = useMobileDetect();
  return mounted
    ? ReactDOM.createPortal(
        <>
          {isMobile || isSmallVertical ? (
            <MobileZipcode {...props} />
          ) : (
            <ZipcodeModal {...props} />
          )}
        </>,
        document.body,
      )
    : null;
};

export default LocationModal;
