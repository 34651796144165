import { Fragment, useRef, FC, MutableRefObject, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import GoogleMapsComponent from "../googleMaps/GoogleMapsComponent";
import PlacesAutocomplete from "../googleMaps/AutocompleteInput";
import type { GoogleGeocodeLocation, Marker } from "utils/googleMapsUtils";
import Image from "next/image";
import locationImg from "@/img/location.svg";

interface ZipcodeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  zipcode: string;
  setZipcode: (newZipcode: string) => void;
  isLoaded: boolean;
  apiKey: string;
  setLocation: (location: string) => void;
  openRef: MutableRefObject<boolean>;
  geocodeLocation: GoogleGeocodeLocation;
  setGeocodeLocation: (location: GoogleGeocodeLocation) => void;
  handleSubmit: (e, modalZipcode?: string) => void;
}

const ZipcodeModal: FC<ZipcodeModalProps> = ({
  open,
  setOpen,
  openRef,
  setZipcode,
  handleSubmit,
  zipcode,
  ...rest
}) => {
  const cancelButtonRef = useRef(null);
  const [modalZipcode, setModalZipcode] = useState(null);

  const validZip = () => {
    if (!modalZipcode || modalZipcode.length !== 5) {
      return false;
    }
    return true;
  };
  const handleClick = (e) => {
    if (validZip()) {
      setZipcode(modalZipcode);
      console.log("---->", modalZipcode, zipcode);
      handleSubmit(e, modalZipcode);
    }
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment} appear={true}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={(e) => handleClick(e)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-gray-500 fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  text-left  transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {rest.isLoaded && (
                  <AlertContainer
                    {...rest}
                    setOpen={setOpen}
                    openRef={openRef}
                    setZipcode={setZipcode}
                    modalZipcode={modalZipcode}
                    setModalZipcode={setModalZipcode}
                    handleClick={handleClick}
                    handleSubmit={handleSubmit}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface AlertContainerProps {
  setOpen: (open: boolean) => void;
  setZipcode: (newZipcode: string) => void;
  isLoaded: boolean;
  setLocation: (location: string) => void;
  openRef: MutableRefObject<boolean>;
  modalZipcode: string;
  setModalZipcode: (modalZipcode: string) => void;
  handleClick: (e) => void;
  geocodeLocation: GoogleGeocodeLocation;
  setGeocodeLocation: (location: GoogleGeocodeLocation) => void;
  handleSubmit: (e, modalZipcode?: string) => void;
}

const AlertContainer: FC<AlertContainerProps> = ({
  setZipcode,
  isLoaded,
  setOpen,
  setLocation,
  openRef,
  modalZipcode,
  setModalZipcode,
  handleClick,
  geocodeLocation,
  setGeocodeLocation,
}) => {
  const [mapRef, setMapRef] = useState(null);
  const [curMarker, setCurMarker] = useState<Marker>(null);

  return (
    <div className="z-50  pt-14">
      <form>
        <div className="items-center" id="location-input-modal">
          <i className="absolute p-3 pl-4">
            <Image src={locationImg} alt="" />
          </i>
          {isLoaded && (
            <PlacesAutocomplete
              mapRef={mapRef}
              curMarker={curMarker}
              setModalZipcode={setModalZipcode}
              setLocation={setLocation}
              modalZipcode={modalZipcode}
              geocodeLocation={geocodeLocation}
            />
          )}
          <input
            className="z-50 hidden h-12 w-full rounded-md border border-gray-600 bg-transparent bg-white pl-12 text-black"
            value={modalZipcode || ""}
            type="text"
            name="zipcode"
            placeholder="Zip Code"
            pattern="/(^\d{5}$)|(^\d{5}-\d{4}$)/"
            minLength={5}
            maxLength={5}
            onInvalid={(e) =>
              e.currentTarget.setCustomValidity(
                "Your zip code must be exactly 5 characters long.",
              )
            }
            required
            onChange={(e) => setModalZipcode(e.target.value)}
          />
        </div>
        {isLoaded && (
          <GoogleMapsComponent
            modalZipcode={modalZipcode}
            setZipcode={setZipcode}
            setModalZipcode={setModalZipcode}
            setCurMarker={setCurMarker}
            curMarker={curMarker}
            setMapRef={setMapRef}
            setLocation={setLocation}
            openRef={openRef}
            setGeocodeLocation={setGeocodeLocation}
          />
        )}
        <button
          onClick={(e) => handleClick(e)}
          className="h-12 w-full rounded-3xl bg-red py-3.5 text-center !text-white"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ZipcodeModal;
