/* eslint-disable no-undef */
import {
  useCallback,
  useState,
  FC,
  CSSProperties,
  useRef,
  useEffect,
  MutableRefObject,
} from "react";
import { GoogleMap } from "@react-google-maps/api";

import type {
  Map,
  LatLng,
  Marker,
  Position,
  GoogleGeocodeLocation,
} from "utils/googleMapsUtils";

interface GoogleMapsProps {
  setZipcode: (newZipcode: string) => void;
  modalZipcode: string;
  setModalZipcode: (newModalZipcode: string) => void;
  mapHeight?: string;
  setMapRef: (mapRef: Map) => void;
  curMarker: Marker;
  setCurMarker: (curMarker: Marker) => void;
  setLocation?: (location: string) => void;
  open?: boolean;
  openRef?: MutableRefObject<boolean>;
  setGeocodeLocation: (location: GoogleGeocodeLocation) => void;
}

const center: Position = {
  lat: 35.1495,
  lng: -90.049,
};

const GoogleMapsComponent: FC<GoogleMapsProps> = ({
  setZipcode,
  modalZipcode,
  setModalZipcode,
  mapHeight = "400px",
  setMapRef,
  curMarker,
  setCurMarker,
  setGeocodeLocation,
  setLocation = null,
  openRef = null,
}) => {
  const [map, setMap] = useState<Map>(null);

  const modalZipcodeRef = useRef(modalZipcode);

  const containerStyle: CSSProperties = {
    width: "100%",
    height: mapHeight,
    margin: "auto",
    borderRadius: "0.5rem",
  };

  // Keeps values current
  useEffect(() => {
    modalZipcodeRef.current = modalZipcode;
  }, [modalZipcode]);

  function updateCenter(map: Map, latLng: LatLng) {
    map.setCenter(latLng);
    map.setZoom(12);
    setMap(map);
  }

  function setLocationCircle(map: Map, latLng: LatLng) {
    // We still create an invisible marker, so that when the user clicks
    // on the map, the marker will be updated be shownx
    const marker = new google.maps.Marker({
      position: latLng,
      map: map,
      // opacity: 0,

      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 40,
        fillColor: "#2563eb",
        fillOpacity: 0.4,
        strokeWeight: 0.4,
      },
    });
    // const circle = new google.maps.Circle({
    //   center: latLng,
    //   map: map,
    //   strokeColor: "#2563eb",
    //   fillColor: "#2563eb",
    //   strokeOpacity: 0.8,
    //   radius: 1200,
    // });
    setCurMarker(marker);
  }

  function getZipcode(latLng: LatLng, initial = false) {
    const geoencoder = new google.maps.Geocoder();
    const res = geoencoder.geocode({ location: latLng }).then((response) => {
      // This error doesn't make any sense. They are the exact same type... Just ignoring it.
      //@ts-ignore
      setGeocodeLocation(response.results[0]);
      for (const address_component of response.results[0].address_components) {
        if (
          address_component.types[0] === "postal_code" &&
          (!initial || (initial && modalZipcodeRef.current == null))
        ) {
          handleLocation(address_component.long_name);
          break;
        }
      }
    });
  }

  const handleLocation = (address: string) => {
    if (!openRef.current) {
      return;
    }
    setModalZipcode(address);
    if (setLocation) {
      setLocation(address);
    }
  };

  const onLoad = useCallback(async function callback(map: Map) {
    const start = Date.now();
    setMapRef(map);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const pos = { lat, lng };
          const latLng = new google.maps.LatLng(pos);

          updateCenter(map, latLng);
          setLocationCircle(map, latLng);
          getZipcode(latLng, true);
        },
      );
    }
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const updateMarker = (latLng: google.maps.LatLng) => {
    console.log(latLng);
    console.log(curMarker);
    if (curMarker) {
      curMarker.setIcon(); // Set the marker to the default icon
      curMarker.setPosition(latLng);
      getZipcode(latLng);
    }
  };

  return (
    <div className="py-2">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ streetViewControl: false }}
        onClick={(e) => updateMarker(e.latLng)}
      >
        {/* Add any additional Google Maps components, markers, etc. here */}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapsComponent;
