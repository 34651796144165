import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import Image from "next/image";
import locationImg from "@/img/location.svg";
import locationArrow from "@/img/location-arrow.svg";
import { FC, useEffect, useState } from "react";
import type {
  Marker,
  Map,
  Place,
  GoogleGeocodeLocation,
} from "utils/googleMapsUtils";

interface AutocompleteProps {
  mapRef: Map;
  curMarker: Marker;
  modalZipcode: string;
  setModalZipcode: (modalZipcode: string | number) => void;
  setLocation?: (location: string) => void;
  geocodeLocation: GoogleGeocodeLocation;
}

const PlacesAutocomplete: FC<AutocompleteProps> = ({
  mapRef,
  curMarker,
  setModalZipcode,
  setLocation = null,
  geocodeLocation,
  modalZipcode,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "YOUR_CALLBACK_NAME",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    googleMaps: { mapRef },
  });
  const [curLocation, setCurLocation] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  useEffect(() => {
    if (geocodeLocation) {
      console.log(geocodeLocation.address_components, "geocodeLocation");
      setCurLocation(geocodeLocation.formatted_address);
      setFormattedAddress(formatAddress(geocodeLocation));
    }
  }, [geocodeLocation]);

  // TODO: Format address.
  // TODO: Add skeleton for when current address isn't know.
  const formatAddress = (geocodeLocation: GoogleGeocodeLocation) => {
    const components = {};
    for (const address_component of geocodeLocation.address_components) {
      if (address_component.types[0] === "route") {
        components["street"] = address_component.long_name;
      } else if (address_component.types[0] === "postal_code") {
        components["zipcode"] = address_component.long_name;
      } else if (address_component.types[0] === "locality") {
        components["city"] = address_component.long_name;
      } else if (address_component.types[0] === "administrative_area_level_1") {
        components["state"] = address_component.short_name;
      }
    }
    const formattedAddress = `${components["street"]}, ${components["city"]}, ${components["state"]} ${components["zipcode"]}`;
    return formattedAddress;
  };

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (place: Place) => () => {
    // If user selects current location and current location is not yet known
    // if (!place) {
    //   return;
    // }
    // If user selects current location
    if (!place.description) {
      setValue("Current Location", false);
      clearSuggestions();
      setLocation("Current Location");
      return;
    }
    // When the user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(place.description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: place.description }).then((results) => {
      let zipcode = getZipCode(results[0], false);
      const latLng = getLatLng(results[0]);
      mapRef.setCenter(latLng);
      if (curMarker) {
        curMarker.setPosition(latLng);
      } else {
        // eslint-disable-next-line no-undef
        curMarker = new google.maps.Marker({
          position: latLng,
          map: mapRef,
        });
      }
      if (setLocation) {
        setLocation(place.description);
      }

      if (!zipcode) {
        // eslint-disable-next-line no-undef
        const geoencoder = new google.maps.Geocoder();
        const res = geoencoder
          .geocode({ location: latLng })
          .then((response) => {
            for (const address_component of response.results[0]
              .address_components) {
              if (address_component.types[0] === "postal_code") {
                zipcode = address_component.long_name;
                console.log(zipcode);
                setModalZipcode(zipcode);
                break;
              }
            }
          });
      }
      setModalZipcode(zipcode);
      console.log(results);
      console.log("📍 Coordinates: ", latLng);
      console.log("📍 Zipcode: ", zipcode);
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          key={place_id}
          onClick={handleSelect(suggestion)}
          className="bg-white"
        >
          <i className="absolute p-3">
            <Image src={locationImg} alt="" />
          </i>
          <div className="hover:outline-slate-600 h-12 w-full overflow-hidden border border-gray-600 pl-12 focus:border-0 focus:border-transparent focus:outline-blue">
            <div>
              <strong>{main_text}</strong>
              <br />
              <small>{secondary_text}</small>
            </div>{" "}
            {/* <div className=" pb-1"></div> */}
          </div>
        </div>
      );
    });

  const CurLocationSuggestion = () => {
    return (
      <div
        key={"current-location-suggestion"}
        onClick={() => {
          handleSelect(geocodeLocation as unknown as Place)();
        }}
        className="bg-white"
      >
        <i className="absolute p-3">
          <Image src={locationArrow} height={24} width={24} alt="" />
        </i>
        <div className="hover:outline-slate-600 h-12 w-full overflow-hidden border border-gray-600 pl-12 focus:border-0 focus:border-transparent focus:outline-blue">
          <div>
            <strong>{`Use Current Location`}</strong>
            <br />
            <small>{geocodeLocation ? formattedAddress : "Loading..."}</small>
          </div>{" "}
          {/* <div className=" pb-1"></div> */}
        </div>
      </div>
    );
  };

  return (
    <div className="text-black" onKeyUp={() => null}>
      <i className="absolute p-3 pl-4">
        <Image src={locationImg} alt="" />
      </i>
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={
          modalZipcode ? modalZipcode : "Add City, State, or Zip Code"
        }
        className="hover:outline-slate-600 h-12 w-full rounded-md border border-gray-600 bg-transparent bg-white pl-12 placeholder:text-gray-701 focus:border-0 focus:border-transparent focus:outline-blue"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <div className="relative z-10">
          <div className="absolute w-full">
            <CurLocationSuggestion />
            {renderSuggestions()}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
