import { FC, useState, MutableRefObject, useEffect } from "react";
import Image from "next/image";
import locationImg from "@/img/location.svg";
import GoogleMapsComponent from "../googleMaps/GoogleMapsComponent";
import { Transition } from "@headlessui/react";
import PlacesAutocomplete from "../googleMaps/AutocompleteInput";
import type { GoogleGeocodeLocation, Marker } from "utils/googleMapsUtils";
interface MobileZipcodeProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  openRef?: MutableRefObject<boolean>;
  zipcode: string;
  setZipcode: (newZipcode: string) => void;
  isLoaded: boolean;
  apiKey: string;
  setLocation: (location: string) => void;
  geocodeLocation: GoogleGeocodeLocation;
  setGeocodeLocation: (location: GoogleGeocodeLocation) => void;
  handleSubmit: (e, modalZipcode?: string) => void;
}

const DarkenScreen = () => {
  return (
    <div className="absolute inset-0 z-20 h-screen bg-black bg-opacity-20" />
  );
};

const MobileZipcode: FC<MobileZipcodeProps> = ({
  open,
  setOpen,
  isLoaded,
  setZipcode,
  setLocation,
  geocodeLocation,
  setGeocodeLocation,
  openRef,
  handleSubmit,
  zipcode,
}) => {
  const [modalZipcode, setModalZipcode] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [curMarker, setCurMarker] = useState<Marker>(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const validZip = () => {
    if (!modalZipcode || modalZipcode.length !== 5) {
      return false;
    }
    return true;
  };

  const handleClick = (e) => {
    if (validZip()) {
      setZipcode(modalZipcode);
      console.log("---->", modalZipcode, zipcode);
      handleSubmit(e, modalZipcode);
    }
    setOpen(false);
  };

  return (
    <>
      {open && <DarkenScreen />}
      <Transition
        show={open}
        appear={true}
        enter="transform transition ease-in-out duration-250"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        className="border-gray-700 fixed inset-x-0 bottom-0 z-40 min-h-fit rounded-3xl rounded-b-none bg-white bg-opacity-100 pb-8 text-black"
      >
        <div>
          <div
            className="flex w-full justify-center pt-4"
            onClick={(e) => handleClick(e)}
            onTouchEnd={(e) => handleClick(e)}
          >
            <svg
              className=" "
              width="48"
              height="4"
              viewBox="0 0 48 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="4" rx="2" fill="#D9D9D9" />
            </svg>
          </div>
          <div className="flex w-full justify-between px-6 pt-4 font-[Montserrat] text-3xl font-bold">
            <div className="">Edit Location</div>
            <div className="self-center" onClick={(e) => handleClick(e)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
                  stroke="#757575"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <form className="px-6 py-4">
            <div className="items-center" id="location-input-modal">
              <i className="absolute p-3 pl-4">
                <Image src={locationImg} alt="" />
              </i>

              <input
                className="hidden"
                value={modalZipcode || ""}
                type="text"
                name="zipcode"
                placeholder="Zip Code"
                pattern="/(^\d{5}$)|(^\d{5}-\d{4}$)/"
                minLength={5}
                maxLength={5}
                onInvalid={(e) =>
                  e.currentTarget.setCustomValidity(
                    "Your zip code must be exactly 5 characters long.",
                  )
                }
                required
                onChange={(e) => setModalZipcode(e.target.value)}
              />
              {isLoaded && (
                <PlacesAutocomplete
                  mapRef={mapRef}
                  curMarker={curMarker}
                  setModalZipcode={setModalZipcode}
                  setLocation={setLocation}
                  modalZipcode={modalZipcode}
                  geocodeLocation={geocodeLocation}
                />
              )}
            </div>
            <div className="">
              {isLoaded ? (
                <GoogleMapsComponent
                  modalZipcode={modalZipcode}
                  setZipcode={setZipcode}
                  setModalZipcode={setModalZipcode}
                  mapHeight="350px"
                  setMapRef={setMapRef}
                  setCurMarker={setCurMarker}
                  curMarker={curMarker}
                  setLocation={setLocation}
                  openRef={openRef}
                  setGeocodeLocation={setGeocodeLocation}
                />
              ) : (
                <div className="h-[350px]" />
              )}
            </div>
            <div className="py-1">
              <button
                onClick={(e) => {
                  handleClick(e);
                }}
                className="h-12 w-full rounded-3xl bg-red py-3.5 text-center !text-white"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Transition>
    </>
  );
};

export default MobileZipcode;
